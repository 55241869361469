<template>
	<div>
		<div class="row mb-5">
			<div v-for="(critere, index) in criteres" :key="index" class="col-12 col-md-6 col-lg-4 col-xl-4 mb-4">
				<div class="card p-3 h-100 card-qualif">
					<div class="row align-items-center mb-3">
						<div class="col">
							<label for="qualification">{{ $t('qualification.categorie_nom') }}</label>
						</div>
						<div class="col-auto">
							<button @click="deleteCritere(index)" class="btn btn-secondary card-trash rounded-pill"><font-awesome-icon :icon="['fal', 'trash-alt']" /></button>
						</div>
					</div>	
					<e-select
						v-model="critere.qualification"
						id="qualification"
						track-by="qualification_libelle"
						label="qualification_libelle"
						:placeholder="$t('qualification.rechercher_categorie')"
						:options="qualification_formatted"
						:searchable="true"
						:show-labels="false"
						:allow-empty="true"
						:sortable="false"
						class="form-group"
					>
						<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.qualification_libelle }}</span></template>
					</e-select>

					<e-select
						v-model="critere.type"
						label="qualification_type_critere"
						:placeholder="$t('qualification.type_critere')"
						:options="type_critere"
						:searchable="false"
						:show-labels="true"
						class="form-group"
					>
						<template slot="option" slot-scope="{ option }">{{ $t('qualification.'+option) }}</template>
	                    <template slot="singleLabel" slot-scope="{ option }">{{ $t('qualification.'+option) }}</template>
					</e-select>

					<QuestionForm
						ref="QuestionForm"
						v-for="question in critere.questions"
						:key="question.question_id"
						question_type="qualification"
						:question_fk="critere.qualification.qualification_id"
						reponse_type="qualification_link"
					/>
				</div>
			</div>
			<div class="text-center col-12">
				<button class="btn btn-secondary rounded-pill mr-sm-3 mb-2 mb-sm-0" @click="addCritere">
					<font-awesome-icon :icon="['fal', 'plus']" /> {{ $t('qualification.add_critere') }}
				</button>
				<b-button variant="primary" class="mb-2 mb-sm-0" @click="searchQualif()" :disabled="processing">
					<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
					{{ $t('global.rechercher') }}
				</b-button>
			</div>
		</div>
		<CustomTable
			v-if="table_id"
			ref="table"
			:id_table="table_id"
			:items.sync="qualification_found_formatted"
			:busy.sync="table_busy"
			primaryKey="entity_id"
			:hrefsRoutes="config_table_hrefs"
		/>

		<ModalEditQualification ref="modal_edit_qualification" @updateQualif="reloadQualification"/>
		<ModalPrintInfosTiers ref="modal_print_infos_tiers"/>
		<ModalPrintInfosHorse ref="modal_print_infos_horse"/>
	</div>
</template>

<script type="text/javascript">
    import Qualification from '@/mixins/Qualification'
    import Horse from '@/mixins/Horse'
	import Navigation from '@/mixins/Navigation'

	import ValidationError from 'GroomyRoot/assets/js/errors/ValidationError'

	export default {
		name: 'FormQualification',
		mixins: [Qualification, Horse, Navigation],
		props: ['qualif_type'],
		data () {
			return {
				criteres: [],
				qualification_type: null,
				qualifications: [],
				processing: false,
				qualification_found: [],
				table_id: null,
				table_busy: false,
				type_critere: ['egale', 'pas_egale'],

				events_tab: {
					'TableAction::DeleteHorses': this.deleteHorses,
					'TableAction::goToPrintInfosTiers': (tiers_ids) => {
						this.openModalPrintInfosTiers(tiers_ids)
					},
					'TableAction::goToManageQualifsTiers': (tiers_ids) => {
						this.openModalQualifs(tiers_ids)
					},
					'TableAction::goToPrintInfosHorse': (params) => {
						this.openModalPrintInfosHorse(params.horse_ids)
					},
				},
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.table_id = "qualif_" + this.qualif_type
				this.qualification_type = await this.getQualificationType(this.qualif_type)
				this.qualifications = await this.getQualifications(this.qualification_type.qualificationtype_id)
				this.addCritere()
			},

			async reloadQualification(){
				this.qualifications = await this.getQualifications(this.qualification_type.qualificationtype_id)
			},

			addCritere() {
				let tmp = {}
				tmp.qualification = {qualification_id: 0}
				tmp.questions = {question_id: 0}
				tmp.type = "egale"

				this.criteres.push(tmp)
			},

			openModalQualifs(tiers_ids) {
				this.$refs.modal_edit_qualification.openModalFromTiers(tiers_ids)
			},

			async searchQualif() {
				this.table_busy = true
				let search = []
				for(let i in this.criteres) {
                	const reponses = await this.$refs.QuestionForm[i].prepareReponses()
                	search.push({
                		qualification_id: this.criteres[i].qualification.qualification_id,
	                	qualification_reponse: reponses,
	                	type: this.criteres[i].type
                	})
				}
				this.qualification_found = await this.searchQualificationAPI(search, this.qualif_type)
				this.$refs.table.refreshTable()
				this.table_busy = false
			},

			deleteCritere(index) {
				this.criteres.splice(index, 1);
			},

			async deleteHorses(horses) {
				const ids = horses.map(horse => horse.horse_id)
			
				this.devalideHorses(ids)
					.then(() => {
						this.$refs.horse_table.refreshTable()
					})
					.finally(() => {
						this.searchQualif()
					})
			},
			openModalPrintInfosTiers(tiers_ids) {
				this.$refs.modal_print_infos_tiers.openModal(tiers_ids)
			},
			openModalPrintInfosHorse(horse_ids) {
				this.$refs.modal_print_infos_horse.openModal(horse_ids)
			},
		},

		computed:{
			qualification_formatted: function() {
				return this.qualifications
			},
			qualification_found_formatted: function() {
                return this.qualification_found
			},
			'config_table_hrefs': function() {
				if(this.qualif_type == "horse") {
					return {
						'horse_nom': {
							routeUniqueName: 'HorseFichePlanning',
							routeName: 'HorseFichePlanning',
							params: {
								horse_id: 'horse_id'
							}
						}
					}
				}
				else if(this.qualif_type == "tiers") {
					return {
						'tiers_rs': {
							routeUniqueName: 'tiersFiche',
							routeName: 'tiersFiche',
							params: {
								tiers_id: 'tiers_id'
							}
						}
					}
				}
				return {}
			}
		},

		components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			ModalEditQualification: () => import('@/components/Contract/ModalEditQualification'),
			ModalPrintInfosHorse : () => import('@/components/Horse/ModalPrintInfosHorse'),
			ModalPrintInfosTiers : () => import('@/components/Tiers/ModalPrintInfosTiers'),
			QuestionForm: () => import('@/components/Questions/QuestionForm')
		}
	}

</script>